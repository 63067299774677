<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>
                            Market Center <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>
                            Sale # <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{ serverErrors.sale_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="sale_id"
                            @change="changeSale()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="sale in sales"
                                :key="sale.id"
                                :value="sale.id"
                            >
                                {{ sale.number }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2" class="pt-2">
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
    } from 'bootstrap-vue'

    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import growerVoucherStoreModule from '@/views/cromis/reports/voucher/growerVoucherStoreModule'
    import axios from '@axios'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner, BBadge,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context){
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref({})

            const seasons = ref([])
            const season_id = ref(null)
            const markets = ref([])
            const market_id = ref(null)
            const sales = ref([])
            const sale_id = ref(null)
            const growers = ref([])
            const grower_id = ref(null)
            const number = ref(null)

            const CROMIS_STORE_MODULE_NAME = 'cromis-growerVoucher'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, growerVoucherStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-growerVoucher/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length === 1){
                                    season_id.value = seasons.value[0].id
                                    changeSeason()
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const changeSeason = async () => {
                if(season_id.value){
                    market_id.value = null
                    await store.dispatch('cromis-growerVoucher/markets', { season_id: season_id.value, report: true })
                                .then(response => {
                                    markets.value = response.data.markets
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(markets.value.length > 0){
                        markets.value.splice(0)
                    }
                }

                serverErrors.value.season_id = null
            }

            const changeMarket = async () => {
                sale_id.value = null
                if(market_id.value){
                    await store.dispatch('cromis-growerVoucher/sales', { season_id: season_id.value, market_id: market_id.value, report: true })
                                .then(response => {
                                    sales.value = response.data.sales
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(sales.value.length > 0){
                        sales.value.splice(0)
                    }
                }

                serverErrors.value.market_id = null
            }

            const changeSale = async () => {
                grower_id.value = null
                number.value = null
                if(sale_id.value){
                    await store.dispatch('cromis-growerVoucher/growers', { sale_id: sale_id.value, report: true })
                                .then(response => {
                                    growers.value = response.data.growers
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(growers.value.length > 0){
                        growers.value.splice(0)
                    }
                }

                serverErrors.value.sale_id = null
            }

            const generateReport = async () => {
                let hasError = false
                serverErrors.value = {
                    sale_id: null,
                }

                if(!sale_id.value){
                    hasError = true
                    serverErrors.value.sale_id = ['Specify sale number']
                }

                if(!hasError){
                    saving.value = true
                    // await store.dispatch('cromis-villageSale/generateVillageSales', { season_id: season_id.value, location_id: location_id.value, number: number.value })
                    //         .then(response => {
                    //             saving.value = false

                    //         })
                    //         .catch(error => {
                    //             console.log('got error', error)
                    //         })

                    await window.open(`${ axios.defaults.baseURL }/reports/sale_summary?sale_id=${ sale_id.value }`, '_blank')
                    saving.value = false
                }
            }

            return {
                dataForm,
                myModal,
                saving,
                serverErrors,

                seasons,
                season_id,
                markets,
                market_id,
                sales,
                sale_id,
                growers,
                grower_id,
                number,
                changeSeason,
                changeMarket,
                changeSale,
                generateReport,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>